import type { ContentWorkflowState } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import type { MessageContentWorkflowFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './MessageWorkflowState.module.pcss';

const MessageWorkflowStateTooltip = dynamic(() => import('./MessageWorkflowStateTooltip'), {
  ssr: false
});

interface Props {
  /**
   * The message to display the content workflow state for
   */
  message: MessageContentWorkflowFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: String;
}

/**
 * Message Content Workflow State
 * @author Arjun Krishna
 */
const MessageWorkflowState: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, hasMessage } = useTranslation(EndUserComponent.MESSAGE_WORKFLOW_STATE);
  const state: ContentWorkflowState = message?.contentWorkflow?.state;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Badge
      variant="light"
      className={cx(className, 'lia-state-wrapper')}
      onMouseEnter={(): void => {
        if (message?.contentWorkflow?.scheduledPublishTime) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={(): void => {
        setShowTooltip(false);
      }}
    >
      <MessageWorkflowStateTooltip
        message={message}
        className={cx('lia-schedule-tooltip')}
        showTooltip={showTooltip}
        shortScheduledTimezone={message?.contentWorkflow?.shortScheduledTimezone}
      >
        <span
          className={
            message?.contentWorkflow?.scheduledPublishTime
              ? cx('lia-message-schedule-text')
              : cx('lia-message-state-text')
          }
        >
          {/**
           * The unknown message was added to fix a failing storyshot test. This may be indicative of a problem elsewhere.
           * If you are seeing 'UNKNOWN' render in the application, or the test output is incorrect, please fix. TODO
           */}
          {hasMessage(state) ? formatMessage(state) : formatMessage('UNKNOWN')}
        </span>
      </MessageWorkflowStateTooltip>
    </Badge>
  );
};

export default MessageWorkflowState;

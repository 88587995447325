import { Interval } from '@aurora/shared-generated/types/graphql-schema-types';
import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

/**
 *
 * @param dateObject
 * @returns Date in UTC time at 0.0.0.0 Hrs
 */
export const getUtcStartTime = (dateObject: Date) => {
  return new Date(
    Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0, 0)
  );
};

/**
 *
 * @param dateObject
 * @returns Date in UTC time at 23.59.0.0 Hrs
 */
export const getUtcEndTime = (dateObject: Date) => {
  return new Date(
    Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 23, 59, 0, 0)
  );
};

/** Returns the date in MMMM dd, yyyy format for date range selection
 * @param dateObject_ Date which needs to format
 */
export function dateToShortFormat(dateObject_: Date): string {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const day = dateObject_.getDate();
  const monthIndex = dateObject_.getMonth();
  const monthName = monthNames[monthIndex];
  const year = dateObject_.getFullYear();
  return `${monthName} ${day}, ${year}`;
}

/** Returns the timezone value in "short" format for date selection
 * @param dateObject_ Date which needs to format
 * @param timeZone timezone which need to format
 */
export function getTimeZoneShortFormat(dateObject_: Date, timeZone: string): string {
  return Intl.DateTimeFormat('en-us', {
    timeZone,
    timeZoneName: 'short'
  })
    .formatToParts(dateObject_)
    .find(part => part.type == 'timeZoneName').value;
}

/**
 * Get the date difference in days
 * @param startTime
 * @param endTime
 * @returns days in number
 */
export function dayDiff(startTime: number, endTime: number) {
  const diffTime = Math.abs(startTime - endTime);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * function to find the difference in years between current year and birthdate year
 *
 * @param birthDate - the date of birth
 * @returns number - the difference between current year and birth year
 */
export function diffYear(birthDate: Date): number {
  const currentDate = new Date();
  let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const birthMonth = birthDate.getMonth();
  const monthDiff = currentMonth - birthMonth;
  if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
    yearsDiff = yearsDiff - 1;
  }
  return yearsDiff;
}

/**
 * Returns true if the provided date matches today's day, month, and year
 *
 * @param date the date to verify
 */
export function isToday(date: Date): boolean {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

/**
 * Returns converted date to the given timezone
 *
 * @param dateObject_ Date whose timezone needs to be converted
 * @param timeZone Timezone to which the date needs to be converted
 * @returns converted date to the given timezone
 */
export function convertTimezone(dateObject_: Date, timeZone: string): string {
  const intlDateObject = new Intl.DateTimeFormat('en-US', {
    timeZone,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
  return intlDateObject.format(dateObject_);
}

/**
 * Get the event ongoing status
 * @param startTime
 * @param endTime
 * @param timeDelta
 * @returns if the event is ongoing
 */
export function isEventOngoing(startTime: number, endTime: number, timeDelta = 0) {
  return (
    Date.now() >= new Date(startTime).getTime() - timeDelta &&
    Date.now() < new Date(endTime).getTime() + timeDelta
  );
}

/**
 * Get the event end status
 * @param endTime
 * @returns if the event is ended
 */
export function isEventEnded(endTime: Date) {
  return endTime.getTime() < Date.now();
}

/**
 * Get the date difference in hours
 * @param time
 * @returns difference betweeen provided time and current time
 */
export function getDiffInHours(time: number): number {
  return Math.abs(Date.now() - new Date(time).getTime()) / 3600000;
}

/**
 * Return interval on the basis of the days
 * @param startTime
 * @param endTime
 * @returns intervals
 */
export const intervalCalculator = (startTime: number, endTime: number) => {
  const selectedDateRange = dayDiff(startTime, endTime);
  if (selectedDateRange < 90) {
    return Interval.OneHour;
  } else {
    return Interval.OneDay;
  }
};

/**
 * Given an epoch time value and a timezone string, returns a Date object with respect to the provided timezone.
 *
 * @param timezone timezone to convert the date to.
 * @param dateTime Epoch time value.
 */
export function getLocalTime(timezone: string, dateTime = new Date()): Date {
  try {
    return new Date(
      new Date(dateTime).toLocaleString('en-US', {
        timeZone: timezone
      })
    );
  } catch (error) {
    log.error(error);
    return new Date(dateTime);
  }
}

import UserLogin from '@aurora/shared-client/components/users/UserLogin/UserLogin';
import { ModeratingActorType } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { canUseDOM } from 'exenv';
import type { ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import { useMeasure } from 'react-use';
import { Breakpoint } from '../../../types/enums';
import type { MessageModerationDataFragment } from '../../../types/graphql-types';
import UserLink from '../../users/UserLink/UserLink';
import useTranslation from '../../useTranslation';
import localStyles from './MessageModerator.module.pcss';

interface Props {
  /**
   * The message to display the moderator for.
   */
  message: MessageModerationDataFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: String;
  /**
   * The fallback component to render in the instance that the moderator is not present.
   */
  fallback?: React.ComponentType<React.PropsWithChildren<unknown>>;
}

/**
 * Moderator details for a message
 *
 * @author Arjun Krishna
 */
const MessageModerator: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className,
  fallback: FallbackComponent = null
}) => {
  const { moderationData: { rejectActorType, rejectUser } = {} } = message;
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.MESSAGE_MODERATOR);
  const { loading: textLoading, formatMessage } = i18n;

  const [isWideScreen, setIsWideScreen] = useState(false);
  const width: number = canUseDOM && window.innerWidth;
  const [userRef, { width: containerWidth }] = useMeasure();
  const isMobile = !isWideScreen;

  useEffect(() => {
    setIsWideScreen(width >= Breakpoint.LG);
  }, [containerWidth, width]);

  if (textLoading) {
    return null;
  }

  /**
   * To render the user details
   */
  function renderUser(): ReactElement {
    return (
      <UserLink
        user={rejectUser}
        useHoverCard
        className={cx(className)}
        data-testid="UserModerator"
      >
        <UserLogin user={rejectUser} className={cx(className, 'lia-user-link')} />
      </UserLink>
    );
  }

  /**
   * To render the system badge
   */
  function renderSystemBadge(): ReactElement {
    return (
      <span className={cx(className)} data-testid="SystemModerator" ref={userRef}>
        {isMobile ? (
          <span>{formatMessage('systemReported')}</span>
        ) : (
          <Badge variant="light">
            <span className={cx('lia-user-system-text')}>{formatMessage('system')}</span>
          </Badge>
        )}
      </span>
    );
  }

  return rejectActorType &&
    rejectActorType.toUpperCase() === ModeratingActorType.Member &&
    rejectUser?.login ? (
    renderUser()
  ) : rejectActorType && rejectActorType.toUpperCase() === ModeratingActorType.System ? (
    renderSystemBadge()
  ) : FallbackComponent ? (
    <FallbackComponent />
  ) : null;
};

export default MessageModerator;
